<template>
  <div>
    <business-hours
        type="select"
        color="#008000"
        :class="{disabled}"
        :days="data"
        :localization="$t('businessHour')"
        :hourFormat24="$t('businessHour.format24h') === 'true'"
    />
  </div>
</template>

<script>
import Tools from "@includes/tools";
import BusinessHours from 'vue-business-hours'

export default {
  components: {
    BusinessHours,
  },

  data() {
    return {
      data: {},
      updatedByComponent: true,
    }
  },

  model: {
    event: 'input',
    prop: 'value'
  },

  props: {
    value: {type: Object, default: () => ({})},
    disabled: Boolean,
  },


  mounted() {
    this.data = this.openingTimesFromApiToUi(this.value)
    this.updatedByComponent = false
  },

  watch: {
    value: {
      handler(openingTimes) {
        if(!this.updatedByComponent) {
          this.data = this.openingTimesFromApiToUi(openingTimes)
        }
        this.updatedByComponent = false
      },
      deep: true
    },

    data: {
      handler() {
        this.updatedByComponent = true
        this.$emit('input', this.openingTimesFromUiToApi())
      },
      deep: true
    }
  },

  methods: {

    openingTimesRow(open = '', close = '', isOpen = false) {
      return {
        id: this.uniqId(),
        open,
        close,
        isOpen
      };
    },

    uniqId() {
      return Math.random().toString(16).slice(2);
    },

    openingTimesFromApiToUi(openingTimes) {

      let data = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      }

      if(!openingTimes) {
        openingTimes = []
      }

      for(let i = 0; i< openingTimes.length; i++) {
        let ot = openingTimes[i]

        if( ot.periodBegin === '00:00' && ot.periodEnd === '23:59') {
          ot.periodBegin = '24hrs'
          ot.periodEnd = '24hrs'
        } else if(ot.periodEnd === '23:59') {
          ot.periodEnd = '24:00'
        }

        data[ot.weekday.toLowerCase()].push(
            this.openingTimesRow(
                ot.periodBegin.replace(':', ''),
                ot.periodEnd.replace(':', ''),
                true
            )
        )
      }

      for(let a in data) {
        if(data[a].length === 0) {
          data[a].push(this.openingTimesRow())
        }
      }

      return data
    },

    openingTimesFromUiToApi() {
      let data = []
      for(let weekday in this.data) {
        for(let h in this.data[weekday]) {
          let hour = this.data[weekday][h];
          if(hour.isOpen) {
            let periodBegin = hour.open;
            let periodEnd = hour.close;

            if(periodBegin === '24hrs') {
              periodBegin = '00:00'
              periodEnd = '23:59'
            }
            else if(periodBegin === 'minuit') {
              periodBegin = '00:00'
            }
            else if(periodEnd === '2400') {
              periodEnd = '23:59'
            } else {
              periodBegin = periodBegin.substr(0, 2) + ':' + periodBegin.substr(2, 2)
              periodEnd = periodEnd.substr(0, 2) + ':' + periodEnd.substr(2, 2)
            }
            data.push({weekday: Tools.ucfirst(weekday), periodBegin, periodEnd })
          }
        }
      }
      return data
    }
  }
}
</script>


<style lang="scss">
.business-hours-container {
  position: relative;
  font-family: dosis, sans-serif !important;

  .flex-table {
    height: 32px !important;
    &.row {
      margin: 0 !important;
    }
  }


  .flex-row {
    select {
      margin-top: 0;
    }
    &.toggle{
      width: 130px;
    }
    &.add {
      button {
        color: var(--v-primary-base) !important;
      }
    }
  }


  .v-switch-label {
    &.v-right {
      right: 20px !important;
    }
  }

  select {
    text-align: center;
    padding: 0 5px !important;
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.38) !important;
    select {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    .v-switch-core {

    }
    .add-hours {
      display: none !important;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

}
</style>
