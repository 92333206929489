<template>
  <v-dialog
    ref="dialog"
    v-model="dialog"
    scrollable
    max-width="1200"
    persistent
  >
    <v-card
        class="location-modal"
    >
      <v-card-title>
        <v-tabs
            v-model="activeTab"
            align-with-title
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab :disabled="!localLocation" v-show="!createOnly || localLocation">
            {{ $t('location.current-location') }}
          </v-tab>
          <v-tab :disabled="!disabled" v-if="!readonly">
            {{ $t('location.find-or-create-location') }}
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-tabs-items v-model="activeTab">
          <v-tab-item >
            <v-form ref="form" v-if="localLocation">
              <v-row>
                <v-col>


                  <v-row>
                    <v-col>
                      <v-text-field
                          ref="nameInput"
                          :label="$t('name')"
                          v-model="localLocation.name"
                          :disabled="disabled"
                          hide-details
                          :rules="[commonRules.required]"
                      />
                    </v-col>
                  </v-row>



                  <v-row dense v-if="localLocation.id">
                    <v-col class="location-stats" :class="{ 'red--text': locationStats.count > 10 }" v-html="$tc('location.used-by-x-stations', locationStats.count, {count: locationStats.count})"></v-col>
                  </v-row>


                  <v-row>
                    <v-col>
                      <tree-select-organization :label="$t('organization.organization')" v-model="localLocation.organizationId" :disabled="disabled" />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="2">
                      <v-text-field :label="$t('number')" v-model="localLocation.address.number" :disabled="disabled" hide-details />
                    </v-col>
                    <v-col md="10">
                      <v-text-field :label="$t('street')" v-model="localLocation.address.street" :disabled="disabled" hide-details />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col md="12">
                      <v-text-field :label="$t('additional-information')" v-model="localLocation.address.additionalInformation" :disabled="disabled" hide-details />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field :label="$t('zipcode')" v-model="localLocation.address.zipcode" :disabled="disabled" hide-details />
                    </v-col>
                    <v-col>
                      <v-text-field :label="$t('city')" v-model="localLocation.address.city" :disabled="disabled" hide-details />
                    </v-col>
                    <v-col>
                      <v-text-field :label="$t('state')" v-model="localLocation.address.state" :disabled="disabled" hide-details />
                    </v-col>
                    <v-col>
                      <available-select
                          ref="input"
                          type="country"
                          v-model="localLocation.address.country"
                          :disabled="disabled"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-switch
                          :label="$t('publish')"
                          v-model="localLocation.publish"
                          :disabled="disabled"
                          hide-details
                      />
                    </v-col>
                    <v-col>
                      <v-switch
                          :label="$t('private')"
                          v-model="localLocation.private"
                          :disabled="disabled"
                          hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                          v-model="localLocation.type"
                          :label="$t('location-type')"
                          :items="types"
                          :disabled="disabled"
                          item-text="label"
                          item-value="value"
                          hide-details
                          :rules="[commonRules.required]"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                          v-model="localLocation.ownerType"
                          :label="$t('location.owner-types')"
                          :items="ownerTypes"
                          :disabled="disabled"
                          item-text="label"
                          item-value="value"
                          hide-details
                          :rules="[commonRules.required]"
                      />
                    </v-col>
                    <v-col>
                      <v-select
                          v-model="localLocation.accessibility"
                          :label="$t('accessibility')"
                          :items="accessibilities"
                          :disabled="disabled"
                          item-text="label"
                          item-value="value"
                          hide-details
                          :rules="[commonRules.required]"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <AvailableSelect
                          type="timezone"
                          :label="$t('location.timezone')"
                          v-model="localLocation.timezone"
                          :disabled="disabled"
                      />
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col>

                      <localized-string
                          :label="$t('location.additional-information')"
                          v-model="localLocation.direction"
                          :disabled="disabled"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                          v-model="localLocation.facilities"
                          :label="$t('facilities')"
                          :items="facilities"
                          :disabled="disabled"
                          item-text="label"
                          item-value="value"
                          hide-details
                          chips
                          deletable-chips
                          small-chips
                          :menu-props_off="{closeOnContentClick:true}"
                          multiple
                      />
                    </v-col>
                  </v-row>

                </v-col>
                <v-col>
                  <v-row>
                    <v-col>
                      <div v-if="showMap && localLocation && localLocation.coordinates">
                        <Map
                            ref="map-container"
                            :zoom="16"
                            @move="centerMarker"
                            @zoomlevelschange="centerMarker"
                            @drag="centerMarker"
                            :on-map-ready="onMapReady"
                            :show-center-control="disabled"
                        >
                          <l-marker
                              v-if="marker"
                              :icon="selectedIcon"
                              :lat-lng="marker"
                          />
                        </Map>
                      </div>
                      <v-row class="mt-1">
                        <v-col class="text-right">
                          <v-btn v-if="!disabled" @click="getPositionFromAddress" x-small>
                            <v-icon left>mdi-crosshairs</v-icon>
                            {{ $t('location.set-location-from-address') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          :label="$t('latitude')"
                          type="number"
                          :disabled="disabled"
                          v-model.number="localLocation.coordinates.latitude"
                          hide-details
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                          :label="$t('longitude')"
                          type="number"
                          :disabled="disabled"
                          v-model.number="localLocation.coordinates.longitude"
                          hide-details
                      />
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col>
                      <v-switch
                          :label="$t('twenty-four-seven')"
                          v-model="localLocation.twentyFourSeven"
                          :disabled="disabled"
                          hide-details
                      />
                    </v-col>
                    <v-col>
                      <v-switch
                          :label="$t('location.charging-when-closed')"
                          v-model="localLocation.chargingWhenClosed"
                          :disabled="disabled"
                          hide-details
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="!localLocation.twentyFourSeven">
                    <v-col>
                      <div class="label">
                        {{ $t('location.openingTimes') }}
                      </div>
                      <opening-times v-model="localLocation.openingTimes" />

                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="search"
                    :loading="loading"
                    :label="$t('location.find-existing-location')"
                    :placeholder="$t('start-typing-to-search')"
                    prepend-icon="mdi-map-search-outline"
                    clearable
                >
                </v-text-field>
              </v-col>
            </v-row>


            <v-row v-if="!createOnly &&  (items.length > 0 || loading)">
              <v-col>

                <h3>{{ $t('location.select-existing-location') }}</h3>
                <v-data-table
                  :headers="[
                      {text: $t('name'), value: 'name'},
                      {text: $t('address'), value: 'address'},
                      {text: $t('location.stations-count'), value: 'count'},
                      {text: '', value: 'options'},
                  ]"
                  hide-default-footer
                  :items="items"
                  :loading="loading"
                >
                  <template #item.address="{item}">
                    {{ item.address.number }} {{ item.address.street }}
                    {{ item.address.zipcode }} {{ item.address.city }}
                    {{ item.address.country_string }}
                  </template>
                  <template #item.options="{item}">
                    <v-btn small color="primary" @click="selectLocation(item)">
                      {{ $t('select') }}
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>

            <template v-if="customers.length > 0 || loadingCustomer">
              <v-divider class="my-6"></v-divider>
              <v-row>
                <v-col>
                  <h3>
                    {{ $tc('location.create-from-a-customer-address') }}
                  </h3>
                  <v-data-table
                      :headers="[
                        {text: $t('lastname'), value: 'lastname'},
                        {text: $t('address'), value: 'address'},
                        {text: '', value: 'options'},
                    ]"
                      :items="customers"
                      :loading="loadingCustomer"
                      hide-default-footer
                  >
                    <template #item.lastname="{item}">
                      <template v-if="item.lastname">
                        {{ item.firstname }} {{ item.lastname }}
                      </template>
                      <template v-else>
                        {{ item.email }}
                      </template>
                    </template>
                    <template #item.address="{item}">
                      {{ item.address?.number }} {{ item.address?.street }}, {{ item.address?.zipcode }} {{ item.address?.city }}, {{ item.address?.country_string }}
                    </template>
                    <template #item.options="{item}">
                      <v-btn small color="primary" @click="createCustomerLocation(item)">
                        {{ $t('create') }}
                      </v-btn>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </template>

            <template v-if="addresses.length > 0 || loadingAddress">
              <v-divider class="my-6"></v-divider>
              <v-row>
                <v-col>
                  <h3>
                    {{ $t('location.create-new-location') }}
                  </h3>
                  <v-data-table
                      :headers="[
                        {text: $t('address'), value: 'address'},
                        {text: '', value: 'options'},
                    ]"
                      :items="addresses"
                      :loading="loadingAddress"
                      hide-default-footer
                  >
                    <template #item.address="{item}">
                      {{ item.address }}<template v-if="item.location">, {{ item.location }}</template>
                    </template>
                    <template #item.options="{item}">
                      <v-btn small color="primary" @click="createLocation(item)">
                        {{ $t('create') }}
                      </v-btn>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </template>


          </v-tab-item>
        </v-tabs-items>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="ma-0" align="center">
          <v-col>
            <v-btn color="primary" small @click="edit(true)" v-if="activeTab === 0 && disabled && !readonly">
              {{ $t('location.edit') }}
            </v-btn>
            <v-btn small @click="delLocation" v-if="activeTab === 0 && disabled && !readonly && location" class="ml-3">
              {{ $t('location.no-location') }}
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <template v-if="!disabled">
              <v-btn  text @click="cancel">
                {{ $t('cancel') }}
              </v-btn>

              <v-btn color="primary" text @click="save">
                {{ localLocation.id ? $t('save') : $t('create') }}
              </v-btn>
            </template>
            <template v-else>

              <v-btn text @click="close">
                {{ $t('close') }}
              </v-btn>
              <v-btn text color="primary" @click="select" v-if="localLocation && activeTab === 0 && !readonly">
                {{ $t('select') }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>


    <v-dialog max-width="600" v-model="showAddressSuggestionModal">
      <v-card>
        <v-card-title>
          {{ $t('location.select-location') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="searchSuggest"
                :loading="loadingSuggest"
                :label="$t('location.find-existing-location')"
                :placeholder="$t('start-typing-to-search')"
                prepend-icon="mdi-map-search-outline"
                clearable
              ></v-text-field>
              <v-data-table
                  :headers="[
                      {text: $t('address'), value: 'address'},
                      {text: '', value: 'options'},
                  ]"
                  :items="addresses"
                  hide-default-footer
              >
                <template #item.address="{item}">
                  {{ item.address }}<template v-if="item.location">, {{ item.location }}</template>
                </template>
                <template #item.options="{item}">
                  <v-btn small color="primary" @click="setLocationFromPlace(item)">
                    {{ $t('select') }}
                  </v-btn>
                </template>

              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col class="text-right">
              <v-btn @click="showAddressSuggestionModal=false" small>
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import Map from "@blocks/Map";
import {Icon, LatLng} from "leaflet";
import {LMarker} from "vue2-leaflet";
import iconSelected from "@images/marker-selected.svg";
import iconShadow from "@images/marker-shadow.png";
import AvailableSelect from "@blocks/Select/AvailableSelect.vue";
import TreeSelectOrganization from "@blocks/Select/TreeSelectOrganization.vue";
import LocationRepository from "@repository/LocationRepository";
import RulesMixin from "@mixins/RulesMixin";
import { v4 as uuidv4 } from 'uuid';
import LocalizedString from "@blocks/LocalizedString.vue";
import CustomerRepository from "@repository/CustomerRepository";
import OpeningTimes from "@blocks/OpeningTimes.vue";

export default {
  components: {
    OpeningTimes,
    LocalizedString,
    TreeSelectOrganization,
    Map,
    LMarker,
    AvailableSelect,
  },
  data() {
    return {
      showAddressSuggestionModal: false,
      loadingSuggest: false,
      activeTab: null,
      tabs: ['Selection', 'Rechercher'],
      oldLocation: null,
      disabled: true,
      loading: false,
      loadingAddress: false,
      loadingCustomer: false,
      dialog: false,
      search: null,
      searchSuggest: null,
      sessionId: null,
      items: [],
      customers: [],
      addresses: [],
      headers: [
        {text: this.$t('number'), value: "address.number"},
        {text: this.$t('street'), value: "address.street"},
        {text: this.$t('zipcode'), value: "address.zipcode"},
        {text: this.$t('city'), value: "address.city"},
        {text: this.$t('country'), value: "address.country"},
        // {text: this.$t('address'), value: "address"},
        {text: '', value: "options"},
      ],
      selectedLocation: null,
      localLocation: null,
      selectedIcon: new Icon({
        iconUrl: iconSelected,
        iconSize: [50, 72],
        iconAnchor: [25, 72],
        shadowUrl: iconShadow,
        shadowSize: [41, 41],
        shadowAnchor: [13, 41]
      }),
      locationStats: {
        count: 0,
      },
      marker: null,
      showMap: false,
      selectedAddress: null,
    }
  },

  props: {
    location: Object,
    readonly: Boolean,
    createOnly: Boolean,
  },

  mixins: [
      RulesMixin
  ],

  mounted() {
    this.initLocalLocation(this.location)
    this.oldLocation = this.cloneObj(this.location)
    if(this.readonly) {
      this.disabled = true
    }
  },

  watch: {
    dialog(val) {
      if(val) {
        this.initLocalLocation(this.location)
      } else {
        this.activeTab = 0
        this.disabled = true
        this.addresses = []
        this.items = []
      }
    },

    activeTab() {
      this.scrollToTop()
      this.addresses = []
    },



    location(val) {
      this.initLocalLocation(val)
    },



    search(val) {
      if(val) {
        if(!this.createOnly) {
          this.searchLocation(val)
        }
        this.searchCustomer(val)
        this.searchAddress(val)
      } else {
        this.items = []
        this.addresses = []
        this.customers = []
      }
    },

    searchSuggest() {
      this.loadSearchSuggest()
    },

    'localLocation.twentyFourSeven': function (value) {
      if(value) {
        this.localLocation.openingTimes = {}
      }
    },

    'localLocation.id': function (value) {
      if(value) {
        LocationRepository.usage(this.localLocation.id).then(result => {
          this.locationStats = result
        })
      }
    }
  },

  computed: {


    accessibilities() {
      return [
        "FREE_ACCESS",
        "RESTRICTED_ACCESS",
        "PAID_ACCESS",
        "TEST_STATION",
      ].map(value => ({label: this.$t(`enums.location-accessibility.${value}`), value }))
    },

    types() {
      return [
        "ON_STREET",
        "PARKING_GARAGE",
        "UNDERGROUND_GARAGE",
        "PARKING_LOT",
        "OTHER",
        "UNKNOWN",
      ].map(value => ({label: this.$t(`enums.location-type.${value}`), value }))
    },

    facilities() {
      return [
        "HOTEL",
        "RESTAURANT",
        "CAFE",
        "MALL",
        "SUPERMARKET",
        "SPORT",
        "RECREATION_AREA",
        "NATURE",
        "MUSEUM",
        "BUS_STOP",
        "TAXI_STAND",
        "TRAIN_STATION",
        "AIRPORT",
        "CARPOOL_PARKING",
        "FUEL_STATION",
        "WIFI",
      ].map(value => ({label: this.$t(`enums.location-facility.${value}`), value }))
    },
    ownerTypes() {
      return [
        "HOME",
        "STORE",
        "OFFICE",
        "EV_STATION",
        "PUBLIC_SITE",
        "PARKING",
        "OTHER",
        "UNKNOWN",
      ].map(value => ({label: this.$t(`enums.location-owner-type.${value}`), value }))
    } ,

    // parkingRestrictions() {
    //   return [
    //     "EV_ONLY",
    //     "PLUGGED",
    //     "DISABLED",
    //     "CUSTOMERS",
    //     "MOTORCYCLES",
    //   ].map(value => ({label: this.$t(`enums.parking-restriction.${value}`), value }))
    // },
  },

  methods: {

    initLocalLocation(location) {

      if(location) {
        let localLocation = this.cloneObj(location)
        if(!localLocation.organizationId) {
          localLocation.organizationId = localLocation.organization?.id
        }
        if(!localLocation.coordinates) {
          localLocation.coordinates = {
            latitude: 48.854449501565355,
            longitude: 2.3469861969351773,
          }
        }
        if(!localLocation.address) {
          localLocation.address = {}
        }
        if(!localLocation.timezone) {
          localLocation.timezone = null
        }
        this.localLocation = localLocation
      }
    },



    createLocation(val) {
      if(val) {
        LocationRepository.getPlace(val.placeId, this.sessionId).then(result => {


          this.resetForm()
          this.localLocation.address = {}
          this.localLocation.address.number = result.detail.number
          this.localLocation.address.street = result.detail.street
          this.localLocation.address.additionalInformation = result.detail.additionalInformation
          this.localLocation.address.city = result.detail.city
          this.localLocation.address.zipcode = result.detail.zipcode
          this.localLocation.address.state = result.detail.state
          this.localLocation.address.country = result.detail.country
          this.localLocation.twentyFourSeven = true
          this.activeTab = 0
          this.disabled = false
          this.direction = []

          this.sessionId = uuidv4();

          this.$nextTick(() => {
            this.showMap = true
            this.$nextTick(() => {
              this.setMapPosition(new LatLng(result.coordinates.lat, result.coordinates.lng))
            })
            setTimeout(() => {
              this.$refs.nameInput.focus()
            }, 500)
          })
        }).catch(e => {
          this.$dialog.notify.error(e.response?.data?.message ?? e.message)
        })

      }
    },

    createCustomerLocation(customer) {
      if(customer) {
          this.resetForm()
          this.localLocation.address = customer.address
          this.localLocation.twentyFourSeven = true
          this.localLocation.type = "ON_STREET"
          this.localLocation.ownerType = "HOME"
          this.localLocation.accessibility = "RESTRICTED_ACCESS"
          this.localLocation.organizationId = customer.organization?.id
          this.searchSuggest = null
          this.activeTab = 0
          this.disabled = false
          this.direction = []
          this.$nextTick(() => {
            this.getPositionFromAddress()
          })
      }
    },

    selectLocation(val) {
      this.initLocalLocation(val)
      this.showMap = true
      this.$nextTick(() => {
        this.activeTab = 0
        this.setMapPosition(new LatLng(val.coordinates.latitude, val.coordinates.longitude))
      })
    },

    scrollToTop() {
      this.$vuetify.goTo(0, {
        container: document.querySelector('.location-modal > .v-card__text'),
        duration: 0,
        offset: 0,
        easing: 'easeInOutCubic',
      })
    },

    cancel() {
      if(!this.createOnly) {
        this.disabled = true
        this.initLocalLocation(this.cloneObj(this.oldLocation))
        if(this.localLocation?.coordinates) {
          this.setMapPosition(new LatLng(this.localLocation.coordinates.latitude, this.localLocation.coordinates.longitude))
        }
      } else {
        this.close()
      }
    },

    edit() {

      if(this.localLocation.coordinates?.latitude) {
        this.showMap = true
        this.$nextTick(() => {
          let center = new LatLng(this.localLocation.coordinates.latitude, this.localLocation.coordinates.longitude)
          let map = this.$refs['map-container'].$refs.map.mapObject;
          map.once("moveend zoomend", () => {
            this.disabled = false
            this.marker = center
            this.localLocation.coordinates.latitude = center.lat
            this.localLocation.coordinates.longitude = center.lng
          })
          map.panTo(center);
        })
      } else {
        this.disabled = false
      }
    },

    save() {

      if(this.$refs.form.validate()) {

        if(!this.localLocation?.coordinates?.latitude) {
          this.notifyError(this.$t('location.error-no-coordinates'))
        }

        this.showLoading(true)
        this.loading = true
        let data = this.cloneObj(this.localLocation)

        let promise = this.localLocation.id ?
            LocationRepository.update(this.localLocation.id, data) :
            LocationRepository.create(data)

        promise.then(response => {
          this.initLocalLocation(response)
          this.loading = false
          this.showLoading(false)

          if(!this.createOnly) {
            if(this.oldLocation?.id === this.localLocation.id) {
              this.$emit('update:location', this.localLocation);
            }
            this.oldLocation = this.cloneObj(this.localLocation)
            this.disabled = true
          } else {
            this.$emit('location-created', this.localLocation);
            this.close()
          }

        }).catch(err => {
          this.notifyError(err)
          this.loading = false
          this.showLoading(false)
        })
      } else {
        this.notifyError(this.$t('rules.form-error'))
      }
    },

    select() {
      this.$emit('update:location', this.localLocation);
      this.close()
    },

    open() {
      this.dialog = true
      this.search = null
      this.sessionId = uuidv4();

      this.$nextTick(() => {
        if(this.location) {
          this.showMap = true
        } else {
          this.localLocation = null
          this.activeTab = 1
        }
      })
    },

    close() {
      this.showMap = false
      this.dialog = false
    },

    searchLocation(val) {
      this.loading = true
      let data={
        search: val,
        limit: 15,
        all: false// used for display all locations in pages location (not used for modal)
      }
      LocationRepository.search(data).then(response => {
        this.items = response.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(err.response.data.message || err.message)
        this.loading = false
      })
    },

    searchCustomer(searchText) {
      this.loadingCustomer = true
      CustomerRepository.search({searchText, withAddress: true}).then(response => {
        this.customers = response.items
        this.loadingCustomer = false
      }).catch(err => {
        this.$dialog.notify.error(err.response.data.message || err.message)
        this.loading = false
      })
    },

    searchAddress(query='') {
      if(query.length >= 3) {
        this.loadingAddress = true
        LocationRepository.searchAddress(query,this.sessionId).then(response => {
          this.addresses = response
          this.loadingAddress = false
        }).catch(err => {
          this.$dialog.notify.error(err.response.data.message || err.message)
          this.loading = false
        })
      }
    },

    getPositionFromAddress() {
      let tmp = []
      if(this.localLocation.address.number) {
        tmp.push(this.localLocation.address.number)
      }
      if(this.localLocation.address.street) {
        tmp.push(this.localLocation.address.street)
      }
      if(this.localLocation.address.additionalInformation) {
        tmp.push(this.localLocation.address.additionalInformation)
      }
      if(this.localLocation.address.city) {
        tmp.push(this.localLocation.address.city)
      }
      if(this.localLocation.address.zipcode) {
        tmp.push(this.localLocation.address.zipcode)
      }
      if(this.localLocation.address.country) {
        tmp.push(this.localLocation.address.country)
      }
      let query = tmp.join(' ')

      this.searchSuggest = query

      this.showAddressSuggestionModal = true
    },

    loadSearchSuggest() {
      this.loadingSuggest = true;
      LocationRepository.searchAddress(this.searchSuggest, this.sessionId).then(response => {
        this.addresses = response
        this.loadingSuggest = false;
      }).catch(err => {
        this.$dialog.notify.error(err.response.data.message || err.message)
        this.loadingSuggest = false;
      })
    },

    setLocationFromPlace(val) {
      if(val) {
        this.showLoading(true)
        LocationRepository.getPlace(val.placeId, this.sessionId).then(result => {
          this.localLocation.address = {}
          this.localLocation.address.number = result.detail.number
          this.localLocation.address.street = result.detail.street
          this.localLocation.address.additionalInformation = result.detail.additionalInformation
          this.localLocation.address.city = result.detail.city
          this.localLocation.address.zipcode = result.detail.zipcode
          this.localLocation.address.state = result.detail.state
          this.localLocation.address.country = result.detail.country
          this.setMapPosition(new LatLng(result.coordinates.lat, result.coordinates.lng))

          this.sessionId = uuidv4();

          this.showAddressSuggestionModal = false
          this.showLoading(false)

        }).catch(e => {
          this.$dialog.notify.error(e.response?.data?.message ?? e.message)
          this.showLoading(false)
        })

      }
    },


    setMapPosition(position) {
      if(position?.lat) {
        this.showMap = true
        this.$refs['map-container']?.$refs.map.mapObject.panTo(position)
        this.marker = [position.lat, position.lng]
        if(!this.localLocation.coordinates) {
          this.localLocation.coordinates = {}
        }
        this.localLocation.coordinates.latitude = position.lat
        this.localLocation.coordinates.longitude = position.lng
      }
    },

    resetForm() {
      this.localLocation = {
        id: null,
        organizationId: null,
        name: null,
        customId: null,
        type: null,
        accessibility: null,
        ownerType: null,
        timezone: null,
        direction: [],
        facilities: [],
        address: {
          number: null,
          street: null,
          zipcode: null,
          city: null,
          state: null,
          country: null,
        },
        coordinates: {
          latitude: null,
          longitude: null,
        },
        twentyFourSeven: false,
        chargingWhenClosed: false,
        private: false,
        publish: false,
      }
    },

    centerMarker() {
      if(!this.disabled) {
        let map = this.$refs['map-container'].$refs.map.mapObject;
        let center = map.getCenter()
        this.marker = center
        this.localLocation.coordinates.latitude = center.lat
        this.localLocation.coordinates.longitude = center.lng
      }
    },

    onMapReady(map) {
      let center = new LatLng(this.localLocation.coordinates.latitude, this.localLocation.coordinates.longitude)
      map.panTo(center)
      this.marker = center
    },

    delLocation() {
      this.$emit('update:location', null);
      this.close()
    },
  }
}
</script>

<style lang="scss">
.location-modal {
  .v-window__container {
    min-height: 80vh;
    .v-window__container {
      min-height: unset;
    }
  }
  .label {
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
  }
  input {
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .map-block {
    border: 3px solid #E53C22;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    height: 400px;
  }

  .location-stats {
    text-align: right;
    font-size: 13px;
    font-style: italic;
  }

  .v-textarea {
    .v-input__slot {
      margin-bottom: 1px !important;
    }
  }

}







</style>
